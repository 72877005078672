<template>
  <div class="header">
    <van-nav-bar
      title="发表评价"
      left-arrow
      :border="false"
      fixed
      placeholder
      @click-left="$router.go(-1)"
    />

    <div class="container">
      <div class="container-box" v-for="(item, index) in commentOrderInfo.listOrderItem" :key="item.id">
        <div class="container-border">
          <img :src="item.picUrl" alt="">
          <div class="container-border-right">
            <p class="container-border-right-p">{{ item.spuName }}</p>
            <p style="color: #999;">规格：{{ item.specInfo }}</p>
          </div>
        </div>

        <div class="container-box-rate">
          <span>描述相符</span>
          <van-rate size="19px" v-model="goodsScore[index]" />
        </div>

        <div class="container-box-upload">
          <van-cell-group>
            <van-field
              v-model="content[index]"
              rows="1"
              autosize
              type="textarea"
              maxlength="200"
              placeholder="与描述是否一致? 商品质量怎么样?"
              show-word-limit
            />
          </van-cell-group>
          <van-uploader 
            preview-size="65"
            v-model="fileList[index]"
            :before-delete="deletePics(index)"
            :after-read="afterRead(index)" />
        </div>
      </div>
    </div>

    <div class="rate"> 
      <div class="rate-star" style="margin-bottom: 15px;">
        <span>物流服务</span>
        <van-rate size="19px" v-model="logisticsScore" />
      </div>
      <div class="rate-star">
        <span>商家服务</span>
        <van-rate size="19px" v-model="serviceScore" />
      </div>
    </div>

    <div class="button">
      <van-button size="large" color="#FB2B53" round @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { upload, saveBatch } from '@/api/tsMallorderDetail'
import { Dialog, Toast } from 'vant';
import { nanoid } from 'nanoid'
export default {
  data () {
    return {
      goodsScore: [], // 商品评分
      logisticsScore: 0, // 物流评分
      serviceScore: 0, // 服务评分
      content: [],
      fileList: [],
      scoreList: [],
      id: ''
    }
  },

  mounted () {
    const index = this.commentOrderInfo.listOrderItem.length
    this.fileList = new Array(index).fill([])
    this.content = new Array(index).fill('')
    this.goodsScore = new Array(index).fill(0)
  },

  computed: {
    ...mapState({
      commentOrderInfo: state => state.shopCar.commentOrderInfo
    })
  },

  methods: {
    afterRead (index) {
      return (file) => {
        file.status = 'uploading'
        file.message = '上传中'
        let formData = new window.FormData()
        formData.append('file', file.file)
        formData.append('fileType', 'imgage/png'),
        formData.append('dir', 'material')
        const nanoId = nanoid()
        upload(formData).then(res => {
          if (res.data.code === 0) {
            file.status = ''
            file.message = ''
            this.scoreList.push({
              picUrls: res.data.data,
              index: index,
              name: file.file.name,
              id: nanoId
            })
          }
        }).catch(() => {
          file.status = 'failed',
          file.message = '上传失败'
        })
      }
    },

    deletePics (index) {
      return ((file, detail) => {
        Dialog.confirm({
          title: '截图',
          message: '确认删除截图？'
        })
        .then(() => {
          const imgArr = JSON.parse(JSON.stringify(this.scoreList))
          this.fileList[index].splice(detail.index, 1)
          imgArr.forEach((x) => {
            if (x.index == index) {
              if (x.name == file.file.name) {
                this.id = x.id
              }
            }
          })
          imgArr.forEach((x, y) => {
            if (x.id == this.id) {
              this.scoreList.splice(y, 1)
            }
          })
        })
        .catch(() => {
          Toast.fail('已取消')
        })
      })
    },

    submit () {
      let contents = JSON.parse(JSON.stringify(this.content))
      const contentsIndex = contents.findIndex(x => x.trim() == '')
      if (contentsIndex != -1) {
        Toast('评价内容不能为空')
        return
      }
      if (this.logisticsScore == '') {
        Toast('请对物流服务进行评分')
        return
      }
      if (this.serviceScore == '') {
        Toast('请对商家服务进行评分')
        return
      }
      const index = this.commentOrderInfo.listOrderItem.length
      const commentOrderInfo = JSON.parse(JSON.stringify(this.commentOrderInfo))
      const content = JSON.parse(JSON.stringify(this.content))
      const goodsScore = JSON.parse(JSON.stringify(this.goodsScore))
      let scoreList = new Array(index).fill({})
      let picUrls = JSON.parse(JSON.stringify(this.scoreList))
      let data = []

      scoreList.forEach((x, y) => {
        x.orderId = commentOrderInfo.orderId,
        x.shopId = commentOrderInfo.shopId
        x.serviceScore = this.serviceScore
        x.logisticsScore = this.logisticsScore
        x.goodsScore = goodsScore[y]
        x.content = content[y]
        let newPicUrls = []
        picUrls.forEach((j) => {
          if (y == j.index) {
            newPicUrls.push(j.picUrls)
          }
        })
        x.picUrls = newPicUrls.join(',')
        x = JSON.parse(JSON.stringify(x))
        data.push(x)
      })

      saveBatch({
        scoreList: data
      }).then(res => {
        if (res.data.code === 0) {
          Toast.success('评价成功')
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  background: #F6F6F6;
  height: 100%;
}
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}
.container {
  width: 94%;
  margin: 13px  auto 0;
  box-sizing: border-box;
  &-border {
    display: flex;
    img {
      margin-right: 4.7%;
      width: 90px;
      height: 90px;
    }
    &-right {
      // width: 60%;
      flex: 1;
      font-size: 14px;
      color: #333;
      overflow: hidden;
      &-p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; 
        -webkit-box-orient: vertical;
      }
    }
  }
  &-box {
    background: white;
    padding: 20px 4%;
    margin-bottom: 10px;
    &-rate {
      margin: 15px 0;
      font-size: 14px;
      color: #333;
      display: flex;
      align-items: center;
      span {
        margin-right: 15px;
      }
    }
    &-upload {
      padding: 16px;
      background: #F7F7F7;
      .van-cell {
        padding: 0 ;
        box-sizing: border-box;
        background: #F7F7F7;
      }
    }
  }
}
.rate {
  background: white;
  width: 94%;
  margin: 15px auto;
  padding: 15px 14px;
  box-sizing: border-box;
  &-star {
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    span {
      margin-right: 15px;
    }
  }
}
.button {
  width: 94%;
  margin: 0 auto;
  // border: 1px solid red;
  padding: 5px 0 15px;
  .van-button--large {
    height: 40px;
  }
}
</style>